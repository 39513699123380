//import image from '../../assets/booking-app-search.png';
//<img src={image} alt="3D Renderer showcase"></img>

const TableBookingApp = () => {
  return(
    <div>
    </div>
  );
}

export default TableBookingApp;